exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-akcijos-js": () => import("./../../../src/pages/akcijos.js" /* webpackChunkName: "component---src-pages-akcijos-js" */),
  "component---src-pages-apie-mus-js": () => import("./../../../src/pages/apie-mus.js" /* webpackChunkName: "component---src-pages-apie-mus-js" */),
  "component---src-pages-atsiskaitymas-js": () => import("./../../../src/pages/atsiskaitymas.js" /* webpackChunkName: "component---src-pages-atsiskaitymas-js" */),
  "component---src-pages-didmenine-prekyba-js": () => import("./../../../src/pages/didmenine-prekyba.js" /* webpackChunkName: "component---src-pages-didmenine-prekyba-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mano-paskyra-js": () => import("./../../../src/pages/mano-paskyra.js" /* webpackChunkName: "component---src-pages-mano-paskyra-js" */),
  "component---src-pages-prekiu-grazinimas-js": () => import("./../../../src/pages/prekiu-grazinimas.js" /* webpackChunkName: "component---src-pages-prekiu-grazinimas-js" */),
  "component---src-pages-slaptazodzio-atkurimas-js": () => import("./../../../src/pages/slaptazodzio-atkurimas.js" /* webpackChunkName: "component---src-pages-slaptazodzio-atkurimas-js" */),
  "component---src-pages-uzsakymas-sekmingas-js": () => import("./../../../src/pages/uzsakymas-sekmingas.js" /* webpackChunkName: "component---src-pages-uzsakymas-sekmingas-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */)
}

