import React, { createContext, useState, useEffect } from "react"
import { navigate } from "gatsby"

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const [token, setToken] = useState(null)

  useEffect(() => {
    const savedToken = localStorage.getItem("jwtToken")
    const savedUser = localStorage.getItem("user")

    if (savedToken) {
      setToken(savedToken)
      if (savedUser) {
        try {
          setUser(JSON.parse(savedUser))
        } catch (error) {
          console.error("Failed to parse savedUser", error)
          localStorage.removeItem("user")
        }
      }
    }
  }, [])

  const login = async (username, password) => {
    try {
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/wp-json/jwt-auth/v1/token`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ username, password }),
        }
      )

      if (!response.ok) {
        throw new Error("Login failed")
      }

      const data = await response.json()
      setToken(data.token)

      const userResponse = await fetch(
        `${process.env.GATSBY_API_URL}/wp-json/wp/v2/users/me`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${data.token}`,
          },
        }
      )

      if (!userResponse.ok) {
        throw new Error("Failed to fetch user info")
      }

      const userInfo = await userResponse.json()
      setUser(userInfo)

      localStorage.setItem("jwtToken", data.token)
      localStorage.setItem("user", JSON.stringify(userInfo))
      navigate("/mano-paskyra")
    } catch (error) {
      console.error("Login error", error)
    }
  }

  const changePassword = async (currentPassword, newPassword) => {
    try {
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/wp-json/wp/v2/users/me/password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            current_password: currentPassword,
            new_password: newPassword,
          }),
        }
      )

      if (!response.ok) {
        throw new Error("Password change failed")
      }
    } catch (error) {
      console.error("Password change error", error)
    }
  }

  const resetPassword = async email => {
    try {
      const response = await fetch(
        `${process.env.GATSBY_API_URL}/wp-json/custom/v1/password-reset`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      )

      if (!response.ok) {
        throw new Error("Password reset failed")
      }
    } catch (error) {
      console.error("Password reset error", error)
    }
  }

  const logout = () => {
    setToken(null)
    setUser(null)
    localStorage.removeItem("jwtToken")
    localStorage.removeItem("user")
    navigate("/")
  }

  return (
    <AuthContext.Provider
      value={{ user, token, login, changePassword, resetPassword, logout }}
    >
      {children}
    </AuthContext.Provider>
  )
}
