import React, { createContext, useContext, useState, useEffect } from "react"

const CartContext = createContext()

export const useCart = () => useContext(CartContext)

const CartProvider = ({ children }) => {
  const [cart, setCart] = useState(() => {
    // Load initial cart from localStorage
    const savedCart = localStorage.getItem("cart")
    return savedCart ? JSON.parse(savedCart) : []
  })

  useEffect(() => {
    // Update localStorage whenever the cart state changes
    localStorage.setItem("cart", JSON.stringify(cart))
  }, [cart])

  const addToCart = product => {
    setCart(prevCart => {
      try {
        const existingItemIndex = prevCart.findIndex(
          item =>
            item.id === product.id &&
            JSON.stringify(item.selectedAttributes) ===
              JSON.stringify(product.selectedAttributes)
        )

        if (existingItemIndex > -1) {
          const updatedCart = prevCart.map((item, index) =>
            index === existingItemIndex
              ? { ...item, quantity: item.quantity + 1 }
              : item
          )
          return updatedCart
        } else {
          const newCart = [...prevCart, { ...product, quantity: 1 }]

          return newCart
        }
      } catch (error) {
        console.error("Error in addToCart:", error)
        return prevCart
      }
    })
  }

  const removeFromCart = index => {
    setCart(prevCart => prevCart.filter((_, i) => i !== index))
  }

  const updateQuantity = (product, newQuantity) => {
    if (newQuantity < 1) return

    setCart(prevCart =>
      prevCart.map(item =>
        item.id === product.id &&
        JSON.stringify(item.selectedAttributes) ===
          JSON.stringify(product.selectedAttributes)
          ? { ...item, quantity: newQuantity }
          : item
      )
    )
  }

  const clearCart = () => {
    setCart([])
    localStorage.removeItem("cart") // Clear the cart from localStorage
  }

  return (
    <CartContext.Provider
      value={{ cart, addToCart, removeFromCart, updateQuantity, clearCart }}
    >
      {children}
    </CartContext.Provider>
  )
}

export default CartProvider
