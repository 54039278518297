import React from "react"
import CartProvider from "./src/context/cartcontext"
import { AuthProvider } from "./src/context/auth"
import "./i18n"

export const wrapRootElement = ({ element }) => {
  return (
    <AuthProvider>
      <CartProvider>{element}</CartProvider>
    </AuthProvider>
  )
}

export const onRouteUpdate = ({ location }) => {
  const path = location.pathname

  // Patikrinkite, ar URL prasideda nuo /en, /lt ar /ru
  if (
    path.startsWith("/en") ||
    path.startsWith("/lt") ||
    path.startsWith("/ru")
  ) {
    // Nukreipkite į pagrindinį puslapį
    window.location.replace("/")
  }
}
