import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import translationEN from "./src/locales/en/translation.json"
import translationLT from "./src/locales/lt/translation.json"
import translationLV from "./src/locales/lv/translation.json"
import translationEE from "./src/locales/ee/translation.json"

const resources = {
  en: {
    translation: translationEN,
  },
  lt: {
    translation: translationLT,
  },
  lv: {
    translation: translationLV,
  },
  ee: {
    translation: translationEE,
  },
}

i18n.use(initReactI18next).init({
  resources,
  lng: "lt", // Numatytasis kalba
  fallbackLng: "en", // Atsarginė kalba
  interpolation: {
    escapeValue: false, // React jau vykdo escape
  },
})
